@import "fonts";
@import '~@experium/react-editor/lib/main.css';

#root {
    height: 100%;
    & > .ant-layout {
        height: 100%; }
    & > div.ant-spin.ant-spin-spinning {
        position: absolute;
        left: 50%;
        top: 50%; } }

.guest-layout {
    width: 400px;
    background-color: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 35px 40px; }

.guest-layout, .guest-content:before {
    vertical-align: middle;
    display: inline-block; }

.guest-content {
    text-align: center;
    display: block;
    background: #2b3d4f;
    &:before {
        content: '';
        height: 100vh;
        width: 1px; }
    .guest-logo {
        margin-bottom: 30px; } }

.logout-btn {
    color: rgba(255, 255, 255, 0.65);
    float: right;
    transition: all .3s;
    cursor: pointer;
    &:hover {
        color: #fff; }
    .anticon {
        margin-right: 8px; } }

.logo {
    display: inline-block;
    margin-top: 10px;
    svg {
        height: 44px;
        width: auto; } }

.ant-layout-sider-trigger {
    background: #293643; }

a {
    font-weight: bold; }

.user-content {
    background: #fff;
    margin: 30px; }

.collapse-hover {
    h3 {
        cursor: pointer;
        &:hover {
            text-decoration: underline; } } }

.toolbar-row {
    border-bottom: 1px solid #e8e8e8;
    padding: 15px;
    min-height: 64px;
    & > div:last-child {
        text-align: right; }
    .toolbar-actions {
        button {
            margin-left: 15px; } }
    h1 {
        display: inline-block;
        float: left;
        line-height: 32px;
        padding-left: 15px; }
    &.detail-toolbar {
        .ant-btn-circle {
            float: left; } }
    .ant-form-item {
        margin-bottom: 5px; }
    .ant-form-item-control {
        line-height: 1; } }

.company-form {
    .ant-btn-danger {
        margin-left: 15px; } }

.mood-slider {
    position: relative;
    padding: 0px 30px;
    .anticon {
        position: absolute;
        top: -2px;
        width: 16px;
        height: 16px;
        line-height: 1;
        font-size: 16px;
        color: rgba(0, 0, 0, .25);
        &:first-child {
            left: 0; }
        &:last-child {
            right: 0; } } }

.checkboxes {
    &.ant-checkbox-group {
        .ant-checkbox-wrapper {
            display: block;
            margin-left: 0; } } }

.photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    &-modal {
        img {
            width: 100%; } }
    &-hover {
        position: absolute;
        background: rgba(0, 0, 0, .5);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        opacity: 0;
        transition: all .3s;
        button {
            background: none;
            border: none;
            color: #fff;
            cursor: pointer;
            font-size: 20px;
            margin: 60px 5px 0;
            &:focus {
                outline: none; } } }
    &-empty {
        background: #ccc;
        color: #fff;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        text-align: center;
        font-size: 60px;
        padding-top: 27px;
        cursor: pointer; }
    &-container {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        position: relative;
        display: block;
        overflow: hidden;
        &:hover {
            .photo-hover {
                opacity: 1; } } } }

.detail {
    &-photo-block {
        width: 180px;
        display: inline-block;
        float: left; }
    &-info-block {
        display: inline-block;
        width: calc(100% - 180px); }
    &-block {
        padding: 0 20px 20px; }
    &-row {
        padding: 10px 0;
        & > div:last-child {
            padding-left: 15px; } } }

.stages {
    margin: 0!important;
    padding: 16px 8px 0;
    overflow: hidden;
    &-content {
        background: #fff;
        margin-bottom: 20px;
        .ant-collapse-content-box {
            padding: 0; } }
    &-title {
        padding: 15px;
        border-bottom: 1px solid #e8e8e8;
        line-height: 32px;
        &:after {
            content: '';
            display: table;
            clear: both; }
        h3 {
            margin-bottom: 0;
            float: left; }
        button,
        .ant-progress {
            float: right; } }
    &-material {
        border-top: 1px solid #e8e8e8;
        padding: 15px;
        position: relative;
        transition: background .3s;
        &:hover {
            background: #e6f7ff; }
        &-edit-btn {
            position: absolute;
            right: 15px;
            top: calc(50% - 16px); }
        .ant-badge-status {
            margin-right: 6px;
            margin-left: 5px; }
        &.viewed {
            .anticon-eye-o {
                color: #1890ff; } }
        &-modules {
            display: inline-block;
            margin-left: 9px;
            &-container {
                margin-top: 5px;
                .anticon-copy {
                    float: left;
                    margin-left: 2px;
                    margin-top: 5px;
                    color: #1890ff; } } } }
    &-form {
        &-theme {
            border-bottom: 1px solid #e8e8e8;
            padding-bottom: 15px;
            &-add-btn {
                margin: 15px 0; } }
        &-center-col {
            text-align: center; }
        &-materials {
            .ant-form-item {
                margin-bottom: 0; }
            &-row {
                border-bottom: 1px solid #e8e8e8;
                background: #fff;
                transition: background .3s;
                &:hover {
                    background: #e6f7ff; }
                &.draggable {
                    border-top: 1px solid #e8e8e8; } }
            .ant-row-flex {
                padding: 7px 0;
                &:not(:last-of-type) {
                    border-bottom: 1px solid #e8e8e8; }
                & > div {
                    padding: 0 5px; } } } }
    .ant-collapse-item {
        border-bottom-color: #e8e8e8; }
    .ant-timeline-item-head {
        top: calc(50% - 20px); }
    .ant-timeline-item-tail {
        top: 0;
        height: 100vh; }
    .ant-collapse-item-disabled {
        .ant-collapse-header {
            cursor: default;
            color: rgba(0, 0, 0, 0.85); } }
    .ant-collapse-content-box {
        padding: 0; }
    .ant-timeline-item-last {
        padding-bottom: 0; } }

.material-modal {
    top: 0;
    height: 100vh;
    .ant-modal-content {
        border-radius: 0; }
    .ant-modal-body {
        padding: 0;
        height: calc(100vh - 55px);
        overflow: hidden; }
    .react-form-builder {
        padding: 15px 24px 15px 10px;
        .sortable-row-content {
            width: 100%;
            display: block;
            margin-left: 50%;
            transform: translateX(-50%); }
        .toolbar {
            position: fixed;
            right: 25px;
            top: 100px;
            max-height: calc(100% - 140px);
            overflow-y: auto; }
        &-preview-btn {
            position: fixed;
            bottom: 0;
            right: -130px;
            z-index: 1; } }
    &-footer {
        padding: 5px 24px 20px 0;
        text-align: right;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 1; }
    &-content-error {
        color: #f5222d;
        padding: 15px 24px 0; } }

.faq {
    &-item {
        &:not(:last-of-type) {
            border-bottom: 1px solid #e8e8e8; }
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        padding: 0 15px;
        &.draggable {
            border-bottom: 1px solid #e8e8e8;
            border-top: 1px solid #e8e8e8; }
        &-content {
            width: 100%;
            padding: 15px 15px 15px 5px; }
        &-left {
            width: 45px;
            .anticon-bars {
                margin-right: 12px; } } } }

.ant-menu {
    & :first-child {
        margin-top: 0; } }

.toolbar-button {
    margin-right: 10px; }

.participants-import-sample {
    text-align: center;
    margin-top: 10px; }
.table {
    .ant-pagination {
        margin-right: 15px; } }

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 2rem;
    background-color: #0000000f;
    transform: translateY(-50%);
    transition: background-color .3s;
    content: ""; }

th.ant-table-cell[style$="text-align: right;"]:last-child:after {
    position: absolute;
    top: 50%;
    left: -1px;
    width: 1px;
    height: 2rem;
    background-color: rgb(250, 250, 250);
    transform: translateY(-50%);
    transition: background-color .3s;
    content: ""; }

.span-link {
    font-weight: bold;
    color: #2b3d4f;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
        color: #f54d2e; } }

.ant-tag-red {
    color: #f54d2e;
    border-color: #f54d2e; }

.stages-material:hover {
    background: #ffeaea; }

.tox-notifications-container,
.tox-statusbar__branding {
  display: none; }

.tox-statusbar {
  display: none !important; }

.ant-input-affix-wrapper {
    padding: 0;
    position: relative;
    .ant-input-prefix {
        position: absolute;
        top: 50%;
        z-index: 2;
        display: flex;
        align-items: center;
        color: rgba(0,0,0,.65);
        line-height: 0;
        transform: translateY(-50%);
        left: 9px; }
    input.ant-input {
        padding: 4px 11px 4px 30px; } }

.ant-input-search.ant-input-affix-wrapper {
    border: none;
    .ant-input-suffix {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0; }
    .ant-input-search-icon:before {
        display: none; }
    input {
        padding: 4px 30px 4px 11px;
        border: 1px solid #d9d9d9;
        &:hover {
            border-color: #ff7859; } } }

.ant-form {
    width: 100%; }

.ant-tabs-nav-container:not(.ant-tabs-nav-container-scrolling) {
    padding-left: 15px;
    padding-right: 15px; }

.ant-collapse-borderless {
    background: #fff; }

.ant-form-item-control-wrapper {
    width: 100%; }

.ant-tabs-nav-list .ant-tabs-tab {
    padding-left: 15px;
    padding-right: 15px; }

.modal-item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    svg {
        margin-right: 8px;
        min-width: 24px; }
    small {
        color: #A7B9CF;
        margin-left: 8px; } }

.ant-modal-title {
    strong {
        color: #2B3D4F; } }
.fc {
    overflow-x: hidden;
    .fc-daygrid-event {
        white-space: normal; }
    .fc-v-event,
    .fc-daygrid-dot-event {
        position: relative;
        border: 0;
        background: #E2EFD8;
        box-shadow: none;
        padding: 6px 6px 6px 8px;
        &:after {
            content: '';
            background: #549E38;
            height: 100%;
            border-radius: 3px 0 0 3px;
            width: 3px;
            position: absolute;
            left: 0;
            top: 0; }
        .fc-event-title {
            font-weight: bold;
            color: #2B3D4F;
            font-size: 12px;
            line-height: 14px; }
        .fc-daygrid-event-dot {
            display: none; }
        &.consultation {
            background: #EBE5DE;
            &:after {
                background: #BA9B66; } } }
    fc-daygrid-event-dot {
        border-color: #549e38; }
    fc-daygrid-day-number {
        padding: 2px 4px 0;
        font-size: 12px; } }

.ant-form-item-has-error {
    .search-input__control {
        border-color: #f54d2e !important; } }

.search-input__indicators {
    .ant-spin.ant-spin-spinning {
        display: inline-flex;
        align-items: center; } }
.ant-tabs-tabpane.ant-tabs-tabpane-active {
    min-height: 40px;
    .ant-spin-dot.ant-spin-dot-spin {
        left: 50%;
        position: absolute; } }
.ant-dropdown-menu.consultant {
    max-height: 260px;
    overflow: auto;
    .ant-dropdown-menu-item-selected {
        background: rgb(245, 77, 46); } }
.ant-layout-header {
    display: flex;
    justify-content: space-between;
    .header-search {
        display: inline-block; } }

.edit-element-fields {
    .multiple-field-item {
        .multiple-field-item-content {
            width: calc(100% - 90px); } } }

.table-star {
    .ant-form-item-label {
        white-space: normal;
        > label {
            height: auto;
            width: 100%;
            text-align: left; } }
    .ant-col-md-4 {
        flex: 0 0 100px;
        max-width: 100px;
        position: static; }
    .ant-col-md-20 {
        padding-right: 8px;
        flex: 1 1 auto;
        max-width: calc(100% - 100px); }
    .ant-alert-info {
        background: transparent;
        margin-bottom: 20px;
        border: 1px solid #6e7375;
        .ant-alert-icon {
            color: #2B3D4E; }
        .ant-alert-message {
            color: rgba(0, 0, 0, 0.65);
            font-size: 13px; } }
    .head-result {
        font-weight: bold;
        margin-bottom: 10px; } }

.tox .tox-promotion {
    display: none; }
.message-input-wrap {
    display: inline-block;
    width: calc( 100% - 38px ); }
.modal-editor-message {
    .message-body {
        margin: 0!important; } }
